import React from 'react';
import {
    Table,
    TableRow, TableHead,
    TableBody,
    TableCell
} from '@material-ui/core';

type solicitationHistory = { historyData?: any }

const SolicitationHistory: React.FC<solicitationHistory> = ({historyData}) => {   
    const count = historyData ? historyData.length : 0;
    
    return (
            <Table className="wrap shadow mt-3">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ backgroundColor: "#fafafa" }}>Atividade</TableCell>
                        <TableCell style={{ backgroundColor: "#fafafa" }}>Data inicial</TableCell>
                        <TableCell style={{ backgroundColor: "#fafafa" }}>Data final</TableCell>
                        <TableCell style={{ backgroundColor: "#fafafa" }}>Usuário</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        historyData.map((h: any, index: number) => 
                            <TableRow
                                style={
                                    count == index + 1 ?
                                        styles.selected
                                        : {}
                                }
                                hover
                            >
                                <TableCell>{h.solicitationName}</TableCell>
                                <TableCell>{h.start_date}</TableCell>
                                <TableCell>{h.end_date}</TableCell>
                                <TableCell>{h.user_name}</TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
    );
}

const styles = {
    table: {
        width: "100%",
        border: "1px solid #DCDCDC",
    },
    selected: {
        backgroundColor: "#B3E5FC",
    },
}

export default SolicitationHistory;