import { Grid, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { Button, Spinner, Tab, Tabs, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import DestinationField from '../../../components/DestinationField';
import { useHistory, useParams } from 'react-router';
import api from '../../../services/Api';
import { useStyles } from '../../../hooks/styles';
import ModalSuccess from '../../../components/ModalSuccess';
import BpmnWithFormViewer from '../../../components/BpmnWithFormViewer';

type param = {
    processId: string,
}

interface FileItem {
    id: number,
    file: string,
    fileObject: File,
    saved: boolean
}

interface TableRowData {
    id: string,
    tipo: string,
    atividade?: string,
    descricaoAtividade?: string,
    responsaveis?: Responsible[],
    destino?: string[],
    tipo_id?: number,
    alinhamento?: number,
    prazo?: number,
    permissions?: { [key: string]: string };
    container?: object,
    subcontainers?: string[],
    width?: number,
    height?: number,
    x?: number,
    y?: number,
    parent?: object,
    children?: object,
    aprovacaoSupervisor?: boolean,
    descricao?: string,
    anexos?: any
}

interface FormsList {
    companyId: number,
    id: number,
    name: string,
}

interface Employee{
    id: string;
    firstname: string;
    lastname?: string;
}

interface Supervisor{
    id: string;
    firstname: string;
    lastname?: string;
}

interface Responsible{
    id: string;
    firstname: string;
    lastname?: string;
}

const sortTableData = (data: TableRowData[]) => {
    const newData: TableRowData[] = [];
    const remainingData: TableRowData[] = [...data]; // Clona o array original
    let cont = 0;
  
    // Processar objetos sem destino e que não são destino de outros objetos
    remainingData.forEach(currentItem => {
      const isDestination = remainingData.some(item => item.destino && item.destino.includes(currentItem.atividade || ''));
      if (!currentItem.destino?.length && !isDestination) {
        newData.push({ ...currentItem, alinhamento: 0 });
        cont += 1;
      }
    });
  
    // Remover os itens já processados
    remainingData.forEach(currentItem => {
      if (newData.some(item => item.id === currentItem.id)) {
        const index = remainingData.indexOf(currentItem);
        if (index !== -1) {
          remainingData.splice(index, 1);
        }
      }
    });
  
    // Processar os demais itens com destinos e dependências
    remainingData.forEach(currentItem => {
      const exists = newData.some(item => item.id === currentItem.id);
      if (!exists) {
        let alinhamento = cont * 10; //refatorar
        if (currentItem.destino && currentItem.destino.length > 0) {
          alinhamento = cont * 10;
        }
        newData.push({ ...currentItem, alinhamento: alinhamento });
      }
  
      if (currentItem.destino && currentItem.destino.length > 0) {
        const order = currentItem.destino.filter(destino => {
          const dependentIndex = newData.findIndex(item => item.atividade === destino);
  
          if (dependentIndex !== -1) {
            // Mover o item dependente para logo após o pai
            const dependentItem = newData.splice(dependentIndex, 1)[0];
            dependentItem.alinhamento = cont * 10;
            const parentIndex = newData.findIndex(item => item.atividade === currentItem.atividade);
            newData.splice(parentIndex + 1, 0, dependentItem);
            return true;
          }
          return false;
        });
      }
      cont += 1;
    });
  
    // Substituir os dados originais com os novos dados ordenados
    data.splice(0, newData.length, ...newData);
    return newData;
};

export function SolicitationProcessForm() {
    const { register, watch, handleSubmit } = useForm();
    const { push: pushHistory, location: { pathname } } = useHistory();
    const { processId } = useParams<param>();
    const [activeTab, setActiveTab] = useState("diagram");
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [errorModal, setErrorModal] = useState<boolean>(false);
    const [errorModalText, setErrorModalText] = useState<string>("");
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [successModalText, setSuccessModalText] = useState<string>("");
    const [redirect, setRedirect] = useState<string>("");
    const [tableData, setTableData] = useState<TableRowData[]>([]);
    const [supervisores, setSupervisores] = useState<Supervisor[]>([]);
    const [sortedData, setSortedData] = useState<TableRowData[]>([]);
    const [formsList, setFormList] = useState<FormsList[]>([]);
    const [formPermissions, setFormPermissisons] = useState<any>([]);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [selectedShape, setSelectedShape] = useState<any>(null);

    const [anchorElDestino, setAnchorElDestino] = useState<HTMLButtonElement | null>(null);
    const [anchorElPermissoes, setAnchorElPermissoes] = useState<HTMLButtonElement | null>(null);
    const [anchorElGateway, setAnchorElGateway] = useState<HTMLButtonElement | null>(null);

    const [processName, setProcessName] = useState<string>("");
    const [form, setForm] = useState<string>("0");
    const [oldForm, setOldForm] = useState<any>([]);
    const [description, setDescription] = useState<string>("");
    const processActivities: any = ["Início", "Fim", "Atividade", "Exclusivo", "Paralelo", "Inclusivo"];

    const [fieldCounter, setFieldCounter] = useState<number>(1);

    const [removeGroups, setRemoveGroups] = useState<number[]>([]);
    const [removeFields, setRemoveFields] = useState<number[]>([]);
    const [removeTables, setRemoveTables] = useState<number[]>([]);

    const [processNameInvalid, setProcessNameInvalid] = useState(false);
    const [groups, setGroups] = useState<any>({
        group: [
            {
                name: "",
                index: 0,
                savedId: 0,
                processId: '',
                inputs: [
                    {
                        id: 0,
                        name: "",
                        type: "",
                        size: 0,
                        rows: 0,
                        columns: 0,
                        mask: "",
                        option: [""],
                        decimal: 0,
                        integerDecimal: 0,
                        extensions: [],
                        otherExtension: "",
                        required: false,
                        savedId: 0
                    }
                ],
                tables: [
                    {
                        id: 0,
                        name: "",
                        dataColumns: [
                            {
                                id: 0,
                                name: "",
                                type: "",
                                size: 50,
                                rows: 1,
                                columns: 3,
                                mask: "",
                                option: [""],
                                extensions: [],
                                otherExtension: "",
                                decimal: 1,
                                integerDecimal: 1,
                                required: false,
                                savedId: 0
                            }
                        ],
                        columns: 0,
                        rows: 0,
                        savedId: 0,
                    }
                ],
            }
        ]
    });

    const classes = useStyles();

    const employeesOptions = employees.map(employee => {
        return employee.lastname ? employee.firstname + " " + employee.lastname : employee.firstname;
    })

    useEffect(() => {
        const fetch = async() => {
            let resp = await api.get("/bpm/form/displayAll");
            setFormList(resp.data);
            
            let users = await api.get("/users");
            setEmployees(users.data);
            
            if (Number(processId)) {
                let data = await api.get(`/bpm/process/display/${processId}`);
                let d = data.data;
                setProcessName(d.name);
                let responsible = d.responsible ? JSON.parse(d.responsible) : [];
                setSupervisores(responsible);
                setForm(String(d.FormBpmId));
                setDescription(d.description);
                let tData = d.data ? JSON.parse(d.data) : [];
                let tForm = d.oldForm ? JSON.parse(d.oldForm) : [];
                setFormPermissisons(tForm.groups);
                setOldForm(tForm);
                setTableData(tData);
                let supervisors = d.supervisors ? JSON.parse(d.supervisors) : [];
                setSupervisores(supervisors);

                if (d.FormBpmId) {
                    let resp = await api.get(`/bpm/form/display/${d.FormBpmId}`);
                    let data = resp.data;
                    let groupData = data.groups;
                    let fieldData = data.fields;
                    let tableData = data.tables;
                    let tableFieldData = data.tableFields;
                    
                    let gCount = groupData.length;
                    let fCount = fieldData.length;
                    let tCount = tableData.length;
                    let tfCount = tableFieldData.length;
                    let groupArr: any = {group: []};
                    let fCounter = fieldCounter;
    
                    for (let i = 0; i < gCount; i++) {
                        let group = groupData[i];
                        groupArr.group.push({
                            name: group.name,
                            index: i,
                            savedId: group.id,
                            inputs: [],
                            tables: [],
                            processId: group.processId
                        });
                    }
    
                    for (let i = 0; i < gCount; i++) {
                        let groupArrI =  groupArr.group[i];
                        let group = groupData[i];
    
                        let input = [];
                        for (let j = 0; j < fCount; j++) {
                            let field = fieldData[j];
                            if (field.groupFormId == group.id) {
                                let option = field.options.split("*|||*");
                                let required = field.isRequired == 'y' ? true : false;
                                let extensions: any = field.extensions ? field.extensions.split(',') : [];
                                input.push({
                                    id: fCounter,
                                    name: field.textLabel,
                                    type: field.type,
                                    size: field.length,
                                    rows: field.rows,
                                    columns: field.lengthColumn,
                                    mask: field.mask,
                                    option,
                                    extensions,
                                    otherExtension: field.otherExtension,
                                    decimal: field.decimal,
                                    integerDecimal: field.integerDecimal,
                                    required,
                                    savedId: field.id,
                                })
                                fCounter++;
                            }
                        }
                        groupArrI.inputs = input;
    
                        let tables = [];
                        let stCounter = 0;
                        for (let k = 0; k < tCount; k++) {
                            let table = tableData[k];
                            if (table.groupFormId == group.id) {
                                tables.push({
                                    id: stCounter,
                                    name: table.name,
                                    dataColumns: [],
                                    columns: 0,
                                    rows: table.countRows,
                                    savedId: table.id,
                                });
    
                                let dataColumns: any = [];
                                let dColumnsCounter = 0;
                                for (let l = 0; l < tfCount; l++) {
                                    let tField = tableFieldData[l];
                                    if (tField.TableFieldBpmId == table.id) {
                                        let option = tField.options.split("*|||*");
                                        let required = tField.isRequired == 'y' ? true : false;
                                        let extensions = tField.extensions ? tField.extensions.split(',') : [];
                                        dataColumns.push({
                                            id: dColumnsCounter,
                                            name: tField.textLabel,
                                            type: tField.type,
                                            size: tField.length,
                                            rows: tField.rows,
                                            columns: tField.lengthColumn,
                                            mask: tField.mask,
                                            option,
                                            extensions,
                                            otherExtension: tField.otherExtension,
                                            decimal: tField.decimal,
                                            integerDecimal: tField.integerDecimal,
                                            required,
                                            savedId: tField.id,
                                        });
                                        dColumnsCounter++;
                                    }
                                }
                                tables[stCounter].dataColumns = dataColumns;
                                stCounter++;
                            }
                        }
                        groupArrI.tables = tables;
                    }
                    setFieldCounter(fCounter);
                    setGroups(groupArr);
                }
            }
        }
        fetch().then();
    }, []);

    useEffect(() => {
        const fetchFormPermissions = async () => {
            try {
                const resp = await api.get(`/bpm/form/display/${form}`);
                setFormPermissisons(resp.data.groups);
                setOldForm(resp.data);
            } catch (error) {
                console.error('Error fetching form permissions:', error);
            }
        };

        if (form && !Number(processId)) {
            fetchFormPermissions();
        }
    }, [form]);
    
    // Vai reordenar os dados sempre que tableData for alterado
    useEffect(() => {
        const sorted = sortTableData(tableData);
        setSortedData(sorted);
        // setIsButtonDisabled(isAtividadeInArray(atividade) || !atividade);
        const sorted2 = sortTableData(tableData);
        setSortedData(sorted2);
    }, [tableData]);

    //Campos Supervisores
    const handleSupervisorChange = (value: string, index: number) => {
        const employee = employees.find(emp => {
            return emp.lastname ? (emp.firstname + " " + emp.lastname) === value : emp.firstname === value;
        });
        if (employee) {
            const newSupervisors = [...supervisores];
            newSupervisors[index] = { ...newSupervisors[index], firstname: employee.firstname, lastname: employee.lastname ?? undefined, id: employee.id };
            setSupervisores(newSupervisors);
        }
    };


    const handleAddSupervisorsField = () => {
        const newSupervisor: Supervisor = { id: (supervisores.length + 1).toString(), firstname: '' };
        setSupervisores([...supervisores, newSupervisor]);
    };

    const handleDeleteSupervisorsField = (index: number) => {
        const newSupervisors = [...supervisores];
        newSupervisors.splice(index, 1);
        setSupervisores(newSupervisors);
    };

    const changeTab = (tab: string) => {
        setActiveTab(tab);
        setSelectedShape(null);
    }

    function validateSupervisoresName() {
        return supervisores.every(supervisor => supervisor.firstname !== '');
    }

    function inputsVerify() {
        if (!processName) {
            setIsSubmit(false);
            setErrorModal(true);
            setActiveTab("general")
            setErrorModalText("O nome do processo é obrigatório!");
            setProcessNameInvalid(true);
            
            return false;
        }

        if (supervisores.length === 0) {
            setIsSubmit(false);
            setErrorModal(true);
            setErrorModalText("O processo deve ter ao menos um supervisor!");
            
            return false;
        }

        if (!validateSupervisoresName()) {
            setIsSubmit(false);
            setErrorModal(true);
            setErrorModalText("Os supervisores devem ter um nome!");

            return false;
        }

        return true;
    }

    async function onSubmit() {
        if (!inputsVerify()) return;
        
        setIsSubmit(true);
        let obj = {
            formId: form,
            groups: groups.group,
            processName,
            formName: `${processName}_form`,
            process: tableData,
            supervisors: supervisores,
            description,
            removeFields,
            removeTables,
            removeGroups,
            processId
        };

        for (const process of obj.process) {
            if (process.anexos && process.anexos.length > 0) {
                for (const anexo of process.anexos) {
                    if (!anexo.saved) {
                        const fd = new FormData();
                        fd.append("file", anexo.fileObject, anexo.file);
                        const upload = await api.post("/bpm/solicitation-process-form/file", fd);
                        anexo.file = upload.data;
                    }
                }
            }
        }

        try {
            let data = JSON.stringify(obj);
            await api.post('/bpm/solicitacao-processo-formulario/save', {data});
            setIsSubmit(false);
            setSuccessModalText(`Processo ${processId !== "0" ? "atualizado" : "criado"} com sucesso!`);
            setSuccessModal(true);
            setRedirect("/bpm/solicitacoes");
        } catch (error) {
            console.log(error);
        }

        setIsSubmit(false);
    }

    return (
        <div style={{position: "relative"}}>
            <ModalSuccess
                msgModal={successModalText}
                showModal={successModal}
                setShowModal={(setShowModal)}
                onClose={() => setSuccessModal(false)}
                redirect={redirect}
            />

            <Modal show={errorModal} onHide={() => setErrorModal(false)}>
                <Modal.Header>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>{errorModalText}</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setErrorModal(false)}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>

            <form className="card card-body pt-4">
                <Grid container spacing={3}>
                    <Grid item lg={6}>
                        <h2>{processId !== '0' ? 'Editar' : 'Novo'} processo</h2>
                    </Grid>
                    <Grid item lg={6}>
                        <div className="h-100 d-flex justify-content-end align-items-end">
                            <Button
                                type="button"
                                variant="primary"
                                disabled={isSubmit}
                                className="mb-2"
                                onClick={() => onSubmit()}
                            >
                                {isSubmit ? <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />

                                    <span className="ml-2">
                                        Aguarde...
                                    </span>
                                </> : <>
                                    <span>
                                        Salvar
                                    </span>
                                </>}
                            </Button>
                        </div>
                    </Grid>
                </Grid>

                <Tabs activeKey={activeTab} onSelect={(tab: string) => changeTab(tab)} id="newproduct-form-tabs" className="mb-5">
                    <Tab eventKey="diagram" title="Diagrama">
                        <BpmnWithFormViewer
                            tableRows={sortedData}
                            setTableRows={setTableData}
                            processActivities={processActivities}
                            createWithForm={true}
                            employees={employees}
                            groups={groups}
                            setGroups={setGroups}
                            selectedShape={selectedShape}
                            setSelectedShape={setSelectedShape}
                            removeGroups={removeGroups}
                            setRemoveGroups={setRemoveGroups}
                            removeFields={removeFields}
                            setRemoveFields={setRemoveFields}
                            removeTables={removeTables}
                            setRemoveTables={setRemoveTables}
                        />
                    </Tab>

                    <Tab eventKey="general" title="Geral">
                        <Grid item lg={6}>
                            <TextField
                                type="string"
                                label="Nome do Processo"
                                size="small"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={processName}
                                onChange={(e) => {
                                    setProcessName(e.target.value);
                                    setProcessNameInvalid(e.target.value ? false : true);
                                }}
                                className={classes.error}
                                error={processNameInvalid}
                            />
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item lg={12}>
                                <TextField
                                    label="Descrição"
                                    multiline
                                    rows="4"
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 5000 }}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </Grid>
                        </Grid>
                        {supervisores.map((item, index) => (
                            <DestinationField
                                key={item.id}
                                value={item.lastname ? `${item.firstname} ${item.lastname}` : item.firstname}
                                options={employeesOptions.filter(Boolean) as string[]}
                                onChange={handleSupervisorChange}
                                onDelete={handleDeleteSupervisorsField}
                                index={index}
                                title="Supervisor"
                            />
                        ))}
                        <Button variant="primary" onClick={handleAddSupervisorsField}>
                            + Adicionar Supervisor
                        </Button>
                    </Tab>
                </Tabs>
            </form>
        </div>
    );
}
